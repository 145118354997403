const FONT_FAMILY = '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    t0: true;
    t1: true;
    t2: true;
    t3: true;
    t3_light: true;
    t4: true;
  }
}

export default {
  fontFamily: FONT_FAMILY,
  h1: {
    fontWeight: 500,
    fontSize: 28,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.24px',
  },
  h3: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 13,
    letterSpacing: '-0.05px'
  },
  body1: {
    fontSize: 16,
  },
  body2: {
    fontSize: 14,
  },

  t0: {
    fontFamily: FONT_FAMILY,
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '40px',
  },
  t1: {
    fontFamily: FONT_FAMILY,
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '28px',
  },
  t2: {
    fontFamily: FONT_FAMILY,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  t3: {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
  },
  t3_light: {
    fontFamily: `"Open Sans", ${FONT_FAMILY}`,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
  },
  t4: {
    fontFamily: FONT_FAMILY,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
  },
};
