import react from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

const GuestGuard = ({ root = '/', children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('login_redirect')) {
      return <Navigate to={decodeURIComponent(searchParams.get('login_redirect'))} />;
    }

    return <Navigate to={root} />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
