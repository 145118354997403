import logger from 'src/lib/logger'

class ProcessingHandler extends EventTarget {
  isProcessing = false
  requestId = null
  timeoutTimerId = null

  audioSent(duration) {
    clearTimeout(this.timeoutTimerId)
    const timeoutDuration = 10 + Math.max(duration * 2, 4)
    this.timeoutTimerId = setTimeout(() => {
      logger.error(`[ProcessingHandler] timeout: requestId ${this.requestId} duration ${duration}`)
      this._setIsProcessing(false)
    }, timeoutDuration * 1000)
    this._setIsProcessing(true)
  }

  audioProcessing(requestId) {
    if (!requestId) { return }

    this.requestId = requestId
  }

  audioProcessed(requestId) {
    if (!requestId) { return }

    if (requestId !== this.requestId) {
      logger.error(`[ProcessingHandler] audioProcessed: requestId mismatch ${requestId} !== ${this.requestId}`)
    }
    clearTimeout(this.timeoutTimerId)
    this.requestId = null
    this._setIsProcessing(false)
  }

  error(requestId, data) {
    const errors = [JSON.stringify(data)]
    if (this.requestId && requestId && requestId !== this.requestId) {
      errors.push(`requestId mismatch ${requestId} !== ${this.requestId}`)
    }
    if (data?.type !== 'TransportError') {
      logger.error(`[ProcessingHandler] error: requestId ${requestId} error ${errors.join(',')}`)
    }

    clearTimeout(this.timeoutTimerId)
    this.requestId = null
    this._setIsProcessing(false)
  }

  _setIsProcessing(isProcessing) {
    this.isProcessing = isProcessing
    this.dispatchEvent(new CustomEvent('change', { detail: isProcessing }))
  }
}

export default ProcessingHandler