/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import LoadingScreen from 'src/components/LoadingScreen'
import useCasrInfo from 'src/hooks/useCasrInfo'
import useAuth from 'src/hooks/useAuth'
import useSettings, { SettingsKeys } from 'src/hooks/useSettings'
import { recognitionPath } from 'src/lib/routing'
import { useReduxStorage } from 'src/redux/store'

const HomePage = () => {

  const { isAuthenticated, user } = useAuth()
  const casrInfo = useCasrInfo()
  const settings = useSettings()
  const recognitionMode = settings.state[SettingsKeys.recognitionMode]
  const lastOpenedTab = settings.state[SettingsKeys.lastOpenedTab]
  const isModePicked = !!recognitionMode
  const subscriptionInfoStorage = useReduxStorage('subscription_info')
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) { return }
    const loadSubscription = async () => {
      try {
        await subscriptionInfoStorage.loadForced()
      } catch (e) {
        console.error(e)
      }
    }
    loadSubscription()

    const f = async () => {
      if (!casrInfo.isModelBuilt) {
        navigate(lastOpenedTab || '/record')
      } else if (!isModePicked) {
        navigate('/mode_picker')
      } else {
        navigate(lastOpenedTab || recognitionPath(recognitionMode))
      }
    }
    f()
  }, [isAuthenticated, user])

  return (<LoadingScreen />)
}

export default HomePage