import { useState, useEffect } from 'react'

const getIsVisible = () => document.visibilityState === 'visible'

const useAppVisibility = () => {
  const [isAppVisible, setIsAppVisible] = useState(getIsVisible())

  const onVisibilityChange = () => setIsAppVisible(getIsVisible())

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  })

  return isAppVisible
}
export default useAppVisibility
