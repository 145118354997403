import axios from 'axios';

import { apiConfig } from 'src/config';
import logger from 'src/lib/logger';

const wrapWithNetworkErrorLogger = (instance) => {
  const methods = ['get', 'post', 'put', 'delete'];

  for (const method of methods) {
    const originalMethod = instance[method];
    instance[method] = async (path, ...args) => {
      const start = new Date();
      try {
        const result = await originalMethod(path, ...args);
        return result;
      } catch (error) {
        const end = new Date();
        const isNetworkError = !error.response;
        if (isNetworkError) {
          logger.error(
            `"${error.message || JSON.stringify(error)}"; ${method} ${path}; started ${start.toISOString()} duration ${(end - start) / 1000}s`,
          );
        }

        throw error;
      }
    };
  }
  return instance;
};

export const api = axios.create({
  baseURL: apiConfig.apiUrl,
});

export const api2 = axios.create({
  baseURL: apiConfig.api2Url,
});

export const ttsApi = axios.create({
  baseURL: apiConfig.ttsApiUrl,
});

export const allApi = [api, api2, ttsApi];
