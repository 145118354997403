/* global BigInt */

import * as ort from "onnxruntime-web/all";
import { getGPUTier } from "detect-gpu";
import { getDataAsJson } from "./cache.js";
import { IcefallPTS7SZip1 } from "./icefall_pts7s_zip1.js";
import { IcefallZip2 } from "./icefall_zip2.js";
import { VAD } from "./vad.js";

export function createVADModel(vadParams, modelPath) {
  const vadModel = new VAD(
    vadParams,
    `${modelPath}/tack_mfcc.onnx`,
    `${modelPath}/vad_model.onnx`
  );

  return vadModel;
}

// this creates an ASR model
// later based on the type of the model in the modelPath, we will create the a compatible model
export async function createASRModel(asrParams, modelPath) {
  let asrModel = null;
  let modelmeta = await getDataAsJson(modelPath + "/modelmeta.json");

  if (modelmeta.encoder.model_type === "zipformer") {
    asrModel = new IcefallPTS7SZip1(asrParams, modelPath);
  } else if (modelmeta.encoder.model_type === "zipformer2") {
    asrModel = new IcefallZip2(asrParams, modelPath);
  } else {
    throw new Error(
      `Unsupported ASR model type: ${modelmeta.encoder.model_type}`
    );
  }

  return asrModel;
}

async function webNnStatusDT(dt) {
  let result = {};
  try {
    const context = await navigator.ml.createContext({ deviceType: dt });
    if (context) {
      try {
        const builder = new MLGraphBuilder(context);
        if (builder) {
          result.supported = true;
          return result;
        } else {
          result.supported = false;
          return result;
        }
      } catch (e) {
        result.supported = false;
        result.error = e.message;
        return result;
      }
    } else {
      result.supported = false;
      return result;
    }
  } catch (ex) {
    result.supported = false;
    result.error = ex.message;
    return result;
  }
}

const supported_gpus = new Set([
  "intel arc 140v gpu",
  "intel readong 0.4 on amd bonaire",
  "intel corporation uhd",
  "intel uhd graphics 770",
  "nvidia geforce rtx 4060 ti",
]);

export async function webNnStatus() {
  let result = {};
  result.gpu_tier = await getGPUTier();
  result.webnn_cpu = await webNnStatusDT("cpu");
  result.webnn_gpu = await webNnStatusDT("gpu");
  if (!supported_gpus.has(result.gpu_tier.gpu)) {
    result.webnn_gpu.supported = false;
    result.webnn_gpu.error = "Only selected GPUs are supported as of now";
  }
  // result.webnn_npu = await webNnStatusDT("npu");
  result.webnn_npu = {
    supported: false,
    error: "NPU is not supported by default as of now",
  };

  return result;
}
