import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { clientApi } from 'src/api/clientApi';
import logger from 'src/lib/logger';

const initialState = {
  data: undefined,
  isDataLoading: false,
  isAllDataLoaded: false,
};

const slice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setIsDataLoading(state) {
      state.isDataLoading = true;
    },
    setPaginatedData(state, action) {
      const { data, newData, page } = action.payload;

      if (!newData.length) {
        state.isAllDataLoaded = true;
      }

      let mergedData;
      if (page === 1) {
        mergedData = newData;
      } else {
        mergedData = [...data, ...newData];
      }
      if (!!mergedData.length) {
        state.data = mergedData;
      }
      state.isDataLoading = false;
    },
    addItem(state, action) {
      const { data, item } = action.payload;
      state.data = [item, ...data];
    },
    upsertItem(state, action) {
      const { data, item } = action.payload;
      const index = _.findIndex(data, { id: item.id });
      if (index !== -1) {
        const newData = [...data];
        newData[index] = item;
        state.data = newData;
      } else {
        state.data = [item, ...data];
      }
    },
    removeItem(state, action) {
      const { data, id } = action.payload;
      state.data = data.filter((o) => o.id !== id);
    },
  },
});

export const load =
  ({ perPage = 25, page = 1 }) =>
  async (dispatch, getState) => {
    dispatch(slice.actions.setIsDataLoading());
    const data = getState().notes.data || [];
    try {
      const newData = await clientApi.fetchNotesPaginated({ perPage, page });
      dispatch(slice.actions.setPaginatedData({ data, newData, page }));
    } catch (e) {
      logger.error(`Failed to load notes: ${e.message}`);
    }
    return data;
  };

export const add = (text) => async (dispatch, getState) => {
  const data = getState().notes.data || [];
  const item = await clientApi.addNote(text);
  dispatch(slice.actions.addItem({ item, data }));
  return item;
};

export const update = (id, text) => async (dispatch, getState) => {
  const data = getState().notes.data || [];
  const item = await clientApi.editNote(id, text);
  dispatch(slice.actions.upsertItem({ item, data }));
  return item;
};

export const remove = (id) => async (dispatch, getState) => {
  const data = getState().notes.data || [];
  const response = await clientApi.deleteNote(id);
  dispatch(slice.actions.removeItem({ id, data }));
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  slice,
};
