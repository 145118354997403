import { createContext, useState, useEffect } from 'react'

export const InstallAppContext = createContext({})

export const InstallAppContextProvider = ({ children }) => {
  const [installPropmt, setInstallPropmt] = useState();

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", beforeInstallListener);
    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallListener);
    };
  }, []);

  const beforeInstallListener = (e) => {
    e.preventDefault();
    setInstallPropmt(e);
  };

  const clearInstallPrompt = () => {
    setInstallPropmt(null);
  }

  return (
    <InstallAppContext.Provider
      value={{
        installPropmt,
        clearInstallPrompt,
      }}
    >
      { children }
    </InstallAppContext.Provider>
  )
}