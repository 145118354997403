import { createContext, useState, useEffect, useRef } from 'react';
import _ from 'lodash';

import useSettings from 'src/hooks/useSettings';
import useAuth from 'src/hooks/useAuth';
import usePrevious from 'src/hooks/usePrevious';

import { teamsApi } from 'src/api/teamsApi';
import { zoomApi } from 'src/api/zoomApi';

export const IntegrationsContext = createContext({});

export const IntegrationsContextProvider = ({ children }) => {
  const settings = useSettings();
  const { user, isAuthenticated } = useAuth();
  const prevIsAuthenticated = usePrevious(isAuthenticated);

  const [msTeamsCaptionsUrl, setMsTeamsCaptionsUrl] = useState();
  const [zoomCaptionsUrl, setZoomCaptionsUrl] = useState();
  const zoomMsgOrderRef = useRef(0);

  useEffect(() => {
    const isLoggedOut = !isAuthenticated && prevIsAuthenticated;
    if (isLoggedOut) {
      clear();
    }
  }, [isAuthenticated, prevIsAuthenticated]);

  useEffect(() => {
    zoomMsgOrderRef.current = 0;
  }, [zoomCaptionsUrl]);

  const runIntegrations = (text) => {
    const namedText = user ? `${user.name}: ${text}` : text;
    if (msTeamsCaptionsUrl) {
      teamsApi.send(msTeamsCaptionsUrl, namedText);
    }
    if (zoomCaptionsUrl) {
      zoomApi.send(settings, zoomCaptionsUrl, text);

      zoomMsgOrderRef.current++;
    }
  };

  const clear = () => {
    setMsTeamsCaptionsUrl(null);
    setZoomCaptionsUrl(null);
  };

  const hasActiveIntegration = () => {
    return !_.isEmpty(msTeamsCaptionsUrl) || !_.isEmpty(zoomCaptionsUrl);
  };

  return (
    <IntegrationsContext.Provider
      value={{
        runIntegrations,
        clear,
        msTeamsCaptionsUrl,
        setMsTeamsCaptionsUrl,
        zoomCaptionsUrl,
        setZoomCaptionsUrl,
        hasActiveIntegration,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};
