
import queryParamsSourceTracker from './queryParamsSourceTracker'
import queryParamsRedirect from './queryParamsRedirect'

const handleQueryParams = () => {
  queryParamsSourceTracker()

  const shouldRenderApp = queryParamsRedirect()
  return {
    shouldRenderApp,
  }
}

export default handleQueryParams