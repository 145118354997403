import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import scenarios from 'src/redux/slices/scenarios';
import models from 'src/redux/slices/models';
import shortcut_phrases from 'src/redux/slices/shortcut_phrases';
import notes from 'src/redux/slices/notes';
import subscription_info from 'src/redux/slices/subscription_info';
import personal_phrases from 'src/redux/slices/personal_phrases';
import profile from 'src/redux/slices/profile';
import voices from 'src/redux/slices/voices';

import service from 'src/redux/slices/service';

const allSlices = {
  scenarios,
  models,
  shortcut_phrases,
  notes,
  subscription_info,
  personal_phrases,
  service,
  profile,
  voices,
};

const rootReducer = combineReducers(
  Object.keys(allSlices).reduce((acc, key) => {
    acc[key] = allSlices[key].slice.reducer;
    return acc;
  }, {}),
);

const store = configureStore({
  reducer: (state, action) => {
    if (action?.type === 'service/logout') {
      state = undefined;
    }
    return rootReducer(state, action);
  },
});

export const useReduxStorage = (name) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state[name]);

  const loadForced = async () => {
    return await dispatch(allSlices[name].load());
  };
  const loadIfNeeded = async () => {
    if (state.isLoaded) {
      return state.data;
    }
    return await dispatch(allSlices[name].load());
  };

  return {
    loadForced,
    loadIfNeeded,
    state,
  };
};

export const useStorage = () => {
  const dispatch = useDispatch();

  const modelsStorage = useReduxStorage('models');
  const subscriptionInfoStorage = useReduxStorage('subscription_info');
  const profileStorage = useReduxStorage('profile');

  const loadInitData = async () => {
    await Promise.all([
      modelsStorage.loadForced(),
      subscriptionInfoStorage.loadForced(),
      profileStorage.loadForced(),
    ]);
  };

  const logout = () => {
    dispatch(service.slice.actions.logout());
  };

  return {
    loadInitData,
    logout,
  };
};

export default store;
