import React, { createContext, useEffect, useState } from 'react'

import { clientApi } from 'src/api/clientApi'
import logger from 'src/lib/logger'

export const FeatureFlagsContext = createContext({})

export const FeatureFlagsContextProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState({})

  useEffect(() => {
    //disabled as not needed for now
    // loadFeatureFlags()
  }, [])

  const loadFeatureFlags = async () => {
    try {
      const data = await clientApi.fetchFeatureFlags()
      setFeatureFlags(data)
    } catch (e) {
      console.error(e)
      logger.error(`loadFeatureToggles error: ${e.message}`)
    }
  }
  return (
    <FeatureFlagsContext.Provider value={{
      featureFlags,
      setFeatureFlags,
    }}>
      { children }
    </FeatureFlagsContext.Provider>
  )
}