/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from '@reduxjs/toolkit'

import { clientApi } from 'src/api/clientApi'
import { PHRASES_SOURCES, DICTATION_PROVIDER } from 'src/constants'


const initialState = {
  data: undefined,
  isLoaded: false,
}

const slice = createSlice({
  name: 'personal_phrases',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
      state.isLoaded = true
    },
  }
})

export const load = () => async (dispatch) => {
  try {
    const data = await clientApi.getPhrasesBySource({ provider: DICTATION_PROVIDER, source: PHRASES_SOURCES.CUSTOM })
    dispatch(slice.actions.setData(data))
    return data
  } catch (e) {
    console.error(e)
  }
}

export default {
  slice,
  load,
}
