const cacheVersion = 1;
const cachePrefix = `casrjs`;
export const cacheName = `${cachePrefix}-${cacheVersion}`;

export async function getDataAsText(url) {
    let cachedData = await getCachedDataAsText(cacheName, url);

    if (cachedData) {
        console.log("Retrieved cached data:", url);
        return cachedData;
    }

    console.log("Fetching fresh data:", url);

    const cacheStorage = await caches.open(cacheName);
    await cacheStorage.add(url);
    cachedData = await getCachedDataAsText(cacheName, url);
    await deleteOldCaches(cacheName);

    return cachedData;
}

export async function getDataAsJson(url) {
    let cachedData = await getCachedDataAsJson(cacheName, url);

    if (cachedData) {
        console.log("Retrieved cached data:", url);
        return cachedData;
    }

    console.log("Fetching fresh data:", url);

    const cacheStorage = await caches.open(cacheName);
    await cacheStorage.add(url);
    cachedData = await getCachedDataAsJson(cacheName, url);
    await deleteOldCaches(cacheName);

    return cachedData;
}

export async function getData(url) {
    let cachedData = await getCachedData(cacheName, url);

    if (cachedData) {
        console.log("Retrieved cached data:", url);
        return cachedData;
    }

    console.log("Fetching fresh data:", url);

    const cacheStorage = await caches.open(cacheName);
    await cacheStorage.add(url);
    cachedData = await getCachedData(cacheName, url);
    await deleteOldCaches(cacheName);

    return cachedData;
}

// Get data from the cache.
async function getCachedDataResponse(cacheName, url) {
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);
    return cachedResponse;
}

async function getCachedData(cacheName, url) {
    const cachedResponse = await getCachedDataResponse(cacheName, url);
    if (!cachedResponse || !cachedResponse.ok) {
        return false;
    }

    return await cachedResponse.arrayBuffer();
}

async function getCachedDataAsText(cacheName, url) {
    const cachedResponse = await getCachedDataResponse(cacheName, url);
    if (!cachedResponse || !cachedResponse.ok) {
        return false;
    }

    return await cachedResponse.text();
}

async function getCachedDataAsJson(cacheName, url) {
    const cachedResponse = await getCachedDataResponse(cacheName, url);
    if (!cachedResponse || !cachedResponse.ok) {
        return false;
    }

    return await cachedResponse.json();
}

// Delete any old caches to respect user's disk space.
export async function deleteOldCaches(currentCache) {
    const keys = await caches.keys();

    for (const key of keys) {
        const isOurCache = key.startsWith(cachePrefix);
        if (currentCache === key || !isOurCache) {
            continue;
        }
        console.log('Deleting cache:', key)
        caches.delete(key);
    }
}
