import { useEffect, useState } from 'react';
import _ from 'lodash';

import useAuth from './useAuth';
import { DICTATION_PROVIDER } from 'src/constants';
import { useReduxStorage } from 'src/redux/store';
import { env } from 'src/config';

const useCasrInfo = () => {
  const { user } = useAuth();
  const [provider, setProvider] = useState(null);

  const modelsInfo = useReduxStorage('models').state.data || [];

  const acousticModelTypes = [
    'acoustic_phoneme',
    'acoustic',
    'acoustic_icefall_pts7s',
  ];
  let isModelBuilt = modelsInfo.some((m) =>
    acousticModelTypes.includes(m.type),
  );
  if (env.isDev && user?.email === 'user_independent@voiceitt.com') {
    isModelBuilt = true;
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.providers_enabled.includes(DICTATION_PROVIDER)) {
      setProvider(DICTATION_PROVIDER);
    } else {
      setProvider(null);
    }
  }, [user, modelsInfo]);

  return { provider, isModelBuilt };
};

export default useCasrInfo;
