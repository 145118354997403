import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import { clientApi } from 'src/api/clientApi'

const initialState = {
  data: undefined,
  isLoaded: false,
}

const slice = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
      state.isLoaded = true
    },
    upsertItem(state, action) {
      const { data, item } = action.payload
      const index = _.findIndex(data, { scenario_id: item.scenario_id })
      if (index !== -1) {
        const newData = [...data]
        newData[index] = item
        state.data = newData
      } else {
        state.data = [...data, item]
      }
    },
  }
})

export const load = () => async (dispatch) => {
  try {
    const data = await clientApi.getScenarios()
    dispatch(slice.actions.setData(data))
    return data
  } catch (e) {
    console.error(e)
  }
}

export const activate = (id) => async (dispatch, getState) => {
  try {
    const data = getState().scenarios.data || []
    await clientApi.activateScenario(id)
    const oldItem = _.find(data, { scenario_id: id })
    const item = { ...oldItem, isActive: true }
    dispatch(slice.actions.upsertItem({ data, item }))
    return item
  } catch (e) {
    console.error(e)
  }
}

export default {
  slice,
  load,
}
