import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'

const NetworkMonitor = () => {
  const { enqueueSnackbar } = useSnackbar()

  const onOnline = () => {
    enqueueSnackbar('You are back online.', { variant: 'success' })
  }

  const onOffline = () => {
    enqueueSnackbar('You are offline. Restore your internet connection to use Voiceitt', { variant: 'error' })
  }

  useEffect(() => {
    window.addEventListener('online', onOnline)
    window.addEventListener('offline', onOffline)

    return () => {
      window.removeEventListener('online', onOnline)
      window.removeEventListener('offline', onOffline)
    }
  }, [])

  return (<></>)
}

export default NetworkMonitor