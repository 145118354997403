import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'service',
  initialState: {},
  reducers: {
    logout(state, action) {} //handled in root reducer
  }
})
export default {
  slice,
}
