// eslint-disable-next-line no-unused-vars
import react from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import useAuth from 'src/hooks/useAuth';
import LoadingScreen from 'src/components/LoadingScreen';
import useUserProfile from 'src/hooks/useUserProfile';
import useSubscription from 'src/hooks/useSubscription';

export const BaseAuthGuard = ({ loginPath = '/login', children }) => {
  const { isInited, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    const originalUrl = `${window.location.pathname}${window.location.hash}${window.location.search}`;
    const loginUrl = `${loginPath}${originalUrl !== '/' ? `?login_redirect=${encodeURIComponent(originalUrl)}` : ''}`;
    return <Navigate to={loginUrl} />;
  }

  if (!isInited) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

const ProfileStatusGuard = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { profileStatus } = useUserProfile();

  if (!profileStatus) {
    return <LoadingScreen />;
  }

  if (!profileStatus.is_mandatory_filled) {
    setTimeout(() => {
      //To suppress "Warning: Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state."
      enqueueSnackbar('Please, setup your profile at first', {
        variant: 'warning',
      });
    });
    const signUpUrl = '/sign-up';
    return <Navigate to={signUpUrl} />;
  }

  return <>{children}</>;
};

const ProfileSubscription = ({ children }) => {
  const { isSubscriptionLocked } = useSubscription();

  const IGNORE_PATHS = ['/', '/menu', '/subscription'];

  const isIgnoredPath = IGNORE_PATHS.includes(window.location.pathname);

  if (!isIgnoredPath && isSubscriptionLocked) {
    const url = '/no_subscription';
    return <Navigate to={url} />;
  }

  return <>{children}</>;
};

const AuthGuard = ({ children }) => {
  return (
    <BaseAuthGuard>
      <ProfileStatusGuard>
        <ProfileSubscription>{children}</ProfileSubscription>
      </ProfileStatusGuard>
    </BaseAuthGuard>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
