export const apiConfig = {
  apiUrl: window._runtime_config.dumbledore_api,
  api2Url: window._runtime_config.dumbledore2_api,
  casrSocketApi: window._runtime_config.casr_socket_api,
  casrDictationSocketApi: window._runtime_config.casr_dictation_socket_api,
  pcControlBaseUrl: window._runtime_config.pc_control_base_url,
  sharedCaptionsSocketUrl: window._runtime_config.shared_captions_socket_url,
  ttsApiUrl: window._runtime_config.tts_api,
};

export const config = {
  pcControlCompanionUrl: window._runtime_config.pc_control_companion_url,
};

export const zoomConfig = {
  redirectUrl: window._runtime_config.zoom_redirect_url,
  clientId: window._runtime_config.zoom_client_id,
};

export const env = {
  isDev:
    window.location.hostname.includes('cd.dev') ||
    window.location.hostname.includes('localhost'),
};
