import _ from 'lodash'

import { SettingsKeys } from 'src/hooks/useSettings'
import { Settings } from 'src/contexts/SettingsContext'


const queryParamsSourceTracker = () => {
  const url = new URL(window.location)

  const searchKeys = ['utm_source', 'utm_medium', 'utm_campaign']
  const searchValues = {}

  for (const key of searchKeys) {
    const rawValue = url.searchParams.get(key)
    if (_.isNull(rawValue)) { continue }

    const value = decodeURIComponent(rawValue)
    searchValues[key] = value
    url.searchParams.delete(key)
  }

  window.history.pushState(null, '', url.toString());


  const sourceTracking = {
    ...(!_.isEmpty(document.referrer) ? { referrer: document.referrer } : {}),
    ...searchValues,
  }

  if (_.isEmpty(sourceTracking)) { return }

  const { set } = Settings()  

  set(SettingsKeys.sourceTracking, sourceTracking)
}

export default queryParamsSourceTracker