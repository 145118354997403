import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export const keyboardEventMode = {
  DEFAULT: 'DEFAULT',
  ADDITIONAL: 'ADDITIONAL',
};

const keyValues = {
  space: ' ',
  return: 'Enter',
};

const transformKeyValue = (name) => {
  return keyValues[name] || name;
};
const initialState = {};
export const EventHandlerContext = createContext(initialState);

export const EventHandlerProvider = ({ children }) => {
  const [eventMap, setEventMap] = useState({});
  const [mode, setMode] = useState(keyboardEventMode.DEFAULT);

  const addHandler = useCallback((key, handler) => {
    setEventMap((prevState) => ({
      ...prevState,
      [transformKeyValue(key.toLowerCase())]: handler,
    }));
  }, []);

  const changeType = useCallback((type) => {
    setMode(type);
  }, []);

  const removeHandler = useCallback((key) => {
    setEventMap((prevState) => {
      let newState = { ...prevState };
      delete newState[transformKeyValue(key.toLowerCase())];
      return newState;
    });
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!event.key) {
        return;
      }
      if (mode === keyboardEventMode.ADDITIONAL) {
        if (
          event.ctrlKey &&
          Object.keys(eventMap).includes(event.key.toLowerCase())
        ) {
          eventMap[event.key.toLowerCase()]();
        }
      } else {
        if (Object.keys(eventMap).includes(event.key.toLowerCase())) {
          eventMap[event.key.toLowerCase()]();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [mode, eventMap]);
  return (
    <EventHandlerContext.Provider
      value={{
        addHandler,
        removeHandler,
        changeType,
        mode,
      }}
    >
      {children}
    </EventHandlerContext.Provider>
  );
};

export const useEventHandlerContext = () => {
  const context = useContext(EventHandlerContext);
  if (context === undefined) {
    throw new Error(
      'useEventContext must be used within a EventHandlerProvider',
    );
  }

  return context;
};
