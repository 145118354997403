import { useReduxStorage } from 'src/redux/store';

const useSubscription = () => {
  const subscriptionInfoStorage = useReduxStorage('subscription_info');
  const subscriptionData = subscriptionInfoStorage.state.data;
  return {
    subscriptionInfo: subscriptionData?.details,
    isSubscriptionLocked: subscriptionData?.is_locked || false,
    isPaymentEnabled: (subscriptionData?.tech_partners || []).length !== 0,
    techPartners: subscriptionData?.tech_partners || [],
    preferredTechPartnerName: subscriptionData?.preferred_tech_partner,
    reload: subscriptionInfoStorage.loadForced,
    isLoaded: subscriptionInfoStorage.state.isLoaded,
  };
};

export default useSubscription;
