import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

import { clientApi } from 'src/api/clientApi';
import logger from 'src/lib/logger';

const initialState = {
  data: undefined,
  isLoaded: false,
  isUpdating: false,
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
      state.isLoaded = true;
    },
    setIsUpdating(state, action) {
      state.isUpdating = action.payload;
    },
  },
});

export const load = () => async (dispatch) => {
  const data = await clientApi.getProfile();
  dispatch(slice.actions.setData(data));
  return data;
};

export const update = (updates, name) => async (dispatch, getState) => {
  const data = getState().profile.data || {};

  const updatedProfile = {
    ...data,
    ...updates,
  };

  dispatch(slice.actions.setIsUpdating(true));
  try {
    await clientApi.updateProfile(updates);
    delete updatedProfile.phone; //as it is not stored in profile
    dispatch(slice.actions.setData(updatedProfile));
  } catch (e) {
    console.error(e);

    //For case with missing profile name field is required https://voiceitt.atlassian.net/browse/VTT-8459
    const isNameMissingInUpdate = !updates.name;
    const isNameRequired = e.status === 400 && e.message === 'name is required';
    if (isNameMissingInUpdate && isNameRequired && !!name) {
      await dispatch(
        update({
          name,
          ...updatedProfile,
        }),
      );
    } else {
      logger.error(`update profile error: ${e.message}`);
    }

    throw e;
  } finally {
    dispatch(slice.actions.setIsUpdating(false));
  }

  return updatedProfile;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  slice,
  load,
};
