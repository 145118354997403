import _ from 'lodash'

const queryParamsRedirect = () => {
  const searchParams = new URLSearchParams(document.location.search)

  const rawValue = searchParams.get('redirect')

  if (!_.isNull(rawValue)) {
    const value = decodeURIComponent(rawValue)
    if (value) {
      window.location.href = value
      return false
    }
  }

  return true
}

export default queryParamsRedirect