import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { clientApi } from 'src/api/clientApi';

const initialState = {
  data: undefined,
  isLoaded: false,
};

const slice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    setData(state, action) {
      const keys = ['version', 'type', 'provider'];
      const oldData = (state.data || []).map((m) => _.pick(m, keys));
      const newData = (action.payload || []).map((m) => _.pick(m, keys));
      if (!_.isEqual(oldData, newData)) {
        state.data = action.payload;
        state.isLoaded = true;
      }
    },
  },
});

export const load = () => async (dispatch) => {
  try {
    const data = await clientApi.getModelsInfo();
    dispatch(slice.actions.setData(data));
    return data;
  } catch (e) {
    console.error(e);
  }
};

export default {
  slice,
  load,
};
