const CryptoJS = require("crypto-js");

const toClientPassword = password => {
  const options = { 
    hasher: CryptoJS.algo.SHA256,
    keySize: 8,
    iterations: 4096
  }

  const hidePass = CryptoJS.PBKDF2(password, 'magicalVoiceitt', options)
  return hidePass.toString(CryptoJS.enc.Base64)
}

export default toClientPassword