
const loadScript = props =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`head > script[src="${props.src}"]`) !== null) return resolve()
    const script = document.createElement("script")
    Object.keys(props).forEach(key => {
      script.setAttribute(key, props[key])
    })
    script.async = true
    document.head.appendChild(script)
    script.onload = resolve
    script.onerror = reject
  })

export default loadScript