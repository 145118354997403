
import { useEffect } from 'react'

import { ALL_ROUTES as WEBEX_ROUTES } from 'src/webex/routes'
import loadScript from 'src/utils/loadScript'

const IGNORE_PATHS = [...WEBEX_ROUTES]

const CookiesConsent = () => {
  useEffect(() => {
    const isIgnoredPath = IGNORE_PATHS.includes(window.location.pathname)
    if (isIgnoredPath) { return }
  
    loadScript({
      'src': "https://js.hs-banner.com/6631765.js",
      'type': "text/javascript",
      'id': "cookieBanner-6631765",
      'data-cookieconsent': "ignore",
      'data-hs-ignore': "true",
      'data-loader': "hs-scriptloader",
      'data-hsjs-portal': "6631765",
      'data-hsjs-env': "prod",
      'data-hsjs-hublet': "na1",
    })
  }, [])

  return null
}

export default CookiesConsent