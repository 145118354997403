import { api, api2, ttsApi } from 'src/utils/axios';
import { DEFAULT_DATASET_TRAIN_SET_PHRASES_COUNT } from 'src/constants';

class ClientApi {
  async getModelsInfo() {
    const { data } = await api.get(`/app/modelsInfo`);
    return data?.models;
  }

  async updateProfile({
    name,
    gender,
    country,
    diagnosis,
    speechLevel,
    nativeLanguage,
    phone,
    region_code,
    role,
    referral_source,
  }) {
    const payload = {
      name,
      gender,
      country,
      diagnosis,
      speechLevel,
      nativeLanguage,
      phone,
      region_code,
      role,
      referral_source,
    };
    return api.post('/app/profile', payload);
  }

  async deleteMyself() {
    return await api2.delete(`/app/end_user/delete_account`);
  }

  async getCustomTrainingDataset(scenarioId) {
    const { data } = await api.get(
      `/app/casr/scenario_train_set/${scenarioId}/custom`,
    );
    return data;
  }

  async getCasrScenarioStats(provider) {
    const { data } = await api.get(`/app/casr/scenarios_stats`, {
      params: { provider },
    });
    return data.stats;
  }
  async getDatasetTrainingDataset(
    scenarioId,
    limit = DEFAULT_DATASET_TRAIN_SET_PHRASES_COUNT,
  ) {
    const { data } = await api.get(
      `/app/casr/scenario_train_set/${scenarioId}/dataset`,
      {
        params: {
          limit,
        },
      },
    );
    return data;
  }

  async getPhrasesBySource({ provider, scenarioId, source }) {
    const { data } = await api.get(`/app/casr/phrases`, {
      params: { provider, scenario_id: scenarioId, sources: source },
    });
    return data;
  }

  async addDatasetPhrase(scenarioId, phraseId) {
    const { data } = await api.post('/app/casr/datasetPhrase', {
      id: phraseId,
      scenario_id: scenarioId,
    });
    return data;
  }

  async addCustomPhrase(text, language, scenarioId) {
    const { data } = await api.post('/app/casr/customPhrase', {
      text,
      language,
      scenario_id: scenarioId,
    });
    return data;
  }

  async getPhrase(phraseId) {
    const { data } = await api.get(`/app/casr/phrase/${phraseId}`);
    return data;
  }

  async deletePhrase(phraseId) {
    const { data } = await api.post('/app/casr/deletePhrase', {
      phrase_id: phraseId,
    });
    return data;
  }

  async editPhrase(phraseId, newText) {
    const { data } = await api.put('/app/casr/editPhrase', {
      phrase_id: phraseId,
      text: newText,
    });
    return data;
  }

  async getScenarios() {
    const { data } = await api.get('/app/scenarios');
    return data.availableScenarios;
  }

  async activateScenario(id) {
    return await api.put(`/app/scenarios/activate/${id}`);
  }

  async uploadExemplar(phraseId, audio, metadata, fileName, fileExtension) {
    const formData = new FormData();
    formData.append(
      'info',
      new Blob([JSON.stringify(metadata)], { type: 'application/json' }),
      `${fileName}.json`,
    );
    formData.append('audiofile', audio, `${fileName}.${fileExtension}`);

    return api.post(`app/casr/upload/training/${phraseId}`, formData);
  }

  async uploadRecognition(audio, info, fileName) {
    const formData = new FormData();
    formData.append(
      'info',
      new Blob([JSON.stringify(info)], { type: 'application/json' }),
      `${fileName}.json`,
    );
    formData.append('audio', audio, `${fileName}.${info.file_ext}`);

    return api2.post(`/app/recording/recognition`, formData);
  }

  async resetAlbCookies() {
    const location = window.location.protocol + '//' + window.location.host;
    return api.get(`${location}/resetCookies`, { withCredentials: true });
  }

  async getSecureDataToken(encryptedPassword, userId) {
    const { data } = await api.post('/authorizeForSecureContent', {
      password: encryptedPassword,
      user_id: userId,
    });
    return data;
  }

  async getProfile() {
    const { data } = await api.get('/app/profile');
    return data;
  }

  async getProfileStatus() {
    const { data } = await api.get('/app/profile/status');
    return data;
  }

  async updateUserSettings(settings) {
    const { data } = await api.post('/app/updateUserSettings', settings);
    return data;
  }

  async getUserSettings() {
    const { data } = await api.get('/app/getUserSettings');
    return data;
  }

  async fetchUserTasks() {
    const { data } = await api.get('/app/tasks');
    return data?.tasks;
  }

  async completeTask(task) {
    const { data } = await api.post('/app/complete_task', { task });
    return data;
  }

  async requestSupport(type, text, metadata) {
    const { data } = await api.post('/app/support', { type, text, metadata });
    return data;
  }

  async talkToBot(text) {
    const { data } = await api2.post('/app/chatgpt/chat', { text });
    return data;
  }

  async fetchShortcutPhrases() {
    const { data } = await api.get('/app/casr/shortcut_phrases');
    return data;
  }

  async addShortcutPhrase(input, output) {
    const { data } = await api.post('/app/casr/shortcut_phrases', {
      input,
      output,
    });
    return data;
  }

  async fetchShortcutPhrase(id) {
    const { data } = await api.get(`/app/casr/shortcut_phrases/${id}`);
    return data;
  }

  async editShortcutPhrase(id, input, output) {
    const { data } = await api.put(`/app/casr/shortcut_phrases/${id}`, {
      input,
      output,
    });
    return data;
  }

  async deleteShortcutPhrase(id) {
    const { data } = await api.delete(`/app/casr/shortcut_phrases/${id}`);
    return data;
  }

  async fetchNotes() {
    const { data } = await api2.get('/app/dictation_notes');
    return data;
  }

  async fetchNotesPaginated({ perPage, page }) {
    const { data } = await api2.get(`/app/dictation_notes`, {
      params: { perPage, page },
    });
    return data;
  }

  async addNote(text) {
    const { data } = await api2.post('/app/dictation_notes', { text });
    return data;
  }

  async editNote(id, text) {
    const { data } = await api2.patch(`/app/dictation_notes/${id}`, { text });
    return data;
  }

  async deleteNote(id) {
    const { data } = await api2.delete(`/app/dictation_notes/${id}`);
    return data;
  }

  async fetchSocialConnections() {
    const { data } = await api2.get(`/app/auth/linked_social_accounts`);
    return data;
  }

  async fetchFeatureFlags() {
    const { data } = await api2.get(`/app/public/feature_flags`);
    return data;
  }

  async fetchSubscriptionInfo() {
    const { data } = await api2.get(`/app/end_user/subscription_info`);
    if (!!data) {
      return data;
    }
  }

  async fetchVoices() {
    const { data } = await ttsApi.get(`/api/v1/list_voices`);
    return data;
  }

  async synthesize({
    text,
    voiceId,
    mediaType = 'mp3', //"pcm16b22k", "wav", "mp3", "flac"
  }) {
    const { data } = await ttsApi.post(
      `/api/v1/synthesize`,
      {
        text,
        voice_id: voiceId,
        media_type: mediaType,
      },
      {
        responseType: 'blob',
      },
    );
    return data;
  }

  async requestSynthesis({
    text,
    voiceId,
    mediaType = 'mp3', //"pcm16b22k", "wav", "mp3", "flac"
    signal,
  }) {
    const { data: requestId } = await ttsApi.post(
      `/api/v1/request_synthesis`,
      {
        text,
        voice_id: voiceId,
        media_type: mediaType,
      },
      { signal },
    );
    return `${ttsApi.defaults.baseURL}/api/v1/synthesize_request/${requestId}`;
  }

  async getCasrModelLinks() {
    const { data } = await api2.get(`/app/end_user/model_links`);
    return data;
  }
}

export const clientApi = new ClientApi();
