import { lazy, Suspense } from 'react'

import { BaseAuthGuard as AuthGuard } from 'src/components/Auth/AuthGuard'
import GuestGuard from 'src/components/Auth/GuestGuard'
import LoadingScreen from 'src/components/LoadingScreen'

const WebexAuthGuard = ({ children }) => AuthGuard({ loginPath: '/webex/login', children })
const WebexGuestGuard = ({ children }) => GuestGuard({ root: '/webex', children })

const Loadable = ({
  guard: Guard,
  component: Component,
  ...props
}) => (
  <Suspense fallback={<LoadingScreen/>}>
    {
      Guard
        ? <Guard><Component {...props}/></Guard>
        : <Component {...props}/>
    }
  </Suspense>
)

const routes = [
  {
    path: 'login',
    element: Loadable({
      guard: WebexGuestGuard,
      component: lazy(() => import('src/webex/pages/login'))
    })
  },
  {
    path: '',
    element: Loadable({
      guard: WebexAuthGuard,
      component: lazy(() => import('src/webex/pages/host')),
    })
  },
  {
    path: 'guest',
    element: Loadable({
      component: lazy(() => import('src/webex/pages/guest')),
    })
  },
  {
    path: '*',
    element: Loadable({
      component: lazy(() => import('src/pages/404')),
    })
  }
]

export const PREFIX = '/webex'
export const ALL_ROUTES = routes.reduce((acc, cur) => {
  const path = cur.path
  if (path !== '*') {
    acc.push(path ? `${PREFIX}/${path}` : PREFIX)
  }
  return acc
}, [])

export default routes