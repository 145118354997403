export async function getMicNameForStream(stream) {
  let audioDeviceLabel = 'unknown';
  const tracks = stream.getAudioTracks();
  if (tracks && tracks.length >= 1 && tracks[0]) {
    const settings = tracks[0].getSettings();
    const chosenDeviceId = settings.deviceId;
    if (chosenDeviceId) {
      let deviceList = [];
      try {
        deviceList = (await navigator.mediaDevices?.enumerateDevices()) || [];
      } catch (error) {
        console.error(error);
      }
      deviceList = deviceList.filter(
        (device) => device.deviceId === chosenDeviceId,
      );
      if (deviceList && deviceList.length >= 1)
        audioDeviceLabel = deviceList[0].label;
    }
  }
  return audioDeviceLabel;
}

export async function getMicName() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return await getMicNameForStream(stream);
}

export async function getMicNameNaive() {
  let deviceList = [];
  try {
    deviceList = (await navigator.mediaDevices?.enumerateDevices()) || [];
  } catch (error) {
    console.error(error);
  }
  return deviceList[0]?.label;
}
