import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@mui/material'

const SCROLLBAR_STYLE = {
  scrollbarColor: '#E8E8E8 transparent',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    width: 8,
    height: 8,
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    borderRadius: 4,
    backgroundColor: '#E8E8E8',
    border: '0px solid transparent',
  },
}

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%',
      backgroundColor: colors.common.white,
      ...SCROLLBAR_STYLE,
    },
    '#root': {
      height: '100%',
      width: '100%',
    }
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
