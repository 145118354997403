import axios from 'axios'
import { SettingsKeys } from 'src/hooks/useSettings'
import { RECOGNITION_LANGUAGE } from 'src/constants'

class ZoomApi {
  
  axiosInstance = axios.create()

  async send(settings, url, text) {
    let seq = settings.state[SettingsKeys.zoomSeq]
    settings.actions.set(SettingsKeys.zoomSeq, ++seq)
    return await this.axiosInstance.post(`${url}&seq=${seq}&lang=${RECOGNITION_LANGUAGE}`, text,
      {
        headers: {
          'Content-Type': 'text/plain',
        }
      })
  }
}

export const zoomApi = new ZoomApi()
