/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import { clientApi } from 'src/api/clientApi'

const initialState = {
  data: undefined,
  isLoaded: false,
}

const slice = createSlice({
  name: 'shortcut_phrases',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
      state.isLoaded = true
    },
    addItem(state, action) {
      const { data, phrase } = action.payload
      state.data = [...data, phrase]
    },
    upsertItem(state, action) {
      const { data, phrase } = action.payload
      const index = _.findIndex(data, { id: phrase.id })
      if (index !== -1) {
        const newData = [...data]
        newData[index] = phrase
        state.data = newData
      } else {
        state.data = [...data, phrase]
      }
    },
    removeItem(state, action) {
      const { data, phraseId } = action.payload
      state.data = data.filter(o => o.id !== phraseId)
    },
  }
})

export const load = () => async (dispatch) => {
  try {
    const data = await clientApi.fetchShortcutPhrases()
    dispatch(slice.actions.setData(data))
    return data
  } catch (e) {
    console.error(e)
  }
}

export const add = (input, output) => async (dispatch, getState) => {
  const data = getState().shortcut_phrases.data || []
  const sameInputRegExp = new RegExp(input, 'i')
  const isDuplicate = _.find(data, item => sameInputRegExp.test(item.input))
  if (isDuplicate) { throw new Error('Shortcut with this input already exists') }
  try {
    const phrase = await clientApi.addShortcutPhrase(input, output)
    dispatch(slice.actions.addItem({ phrase, data }))
    return phrase
  } catch (e) {
    const isDuplicate = e.status === 409
    if (isDuplicate) { throw new Error('Shortcut with this input already exists') }
    throw e
  }
}

export const getOne = (id) => async (dispatch, getState) => {
  try {
    const data = getState().shortcut_phrases.data || []
    const phrase = await clientApi.fetchShortcutPhrase(id)
    dispatch(slice.actions.upsertItem({ phrase, data }))
    return phrase
  } catch (e) {
    console.error(e)
  }
}

export const update = (id, input, output) => async (dispatch, getState) => {
  try {
    const data = getState().shortcut_phrases.data || []
    const sameInputRegExp = new RegExp(input, 'i')
    const isDuplicate = _.find(data, item => item.id !== id && sameInputRegExp.test(item.input))
    if (isDuplicate) { throw new Error('Shortcut with this input already exists') }

    const phrase = await clientApi.editShortcutPhrase(id, input, output)
    dispatch(slice.actions.upsertItem({ phrase, data }))
    return phrase
  } catch (e) {
    const isDuplicate = e.status === 409
    if (isDuplicate) { throw new Error('Shortcut with this input already exists') }
    throw e
  }
}

export const remove = (id) => async (dispatch, getState) => {
  try {
    const data = getState().shortcut_phrases.data || []
    const response = await clientApi.deleteShortcutPhrase(id)
    dispatch(slice.actions.removeItem({ phraseId: id, data }))
    return response
  } catch (e) {
    console.error(e)
  }
}

export default {
  slice,
  load,
}
