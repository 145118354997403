/* eslint-disable import/no-anonymous-default-export */
import { createSlice } from '@reduxjs/toolkit';

import { clientApi } from 'src/api/clientApi';

const initialState = {
  data: undefined,
  isLoaded: false,
};

const slice = createSlice({
  name: 'voices',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
      state.isLoaded = true;
    },
  },
});

export const load = () => async (dispatch) => {
  try {
    const data = await clientApi.fetchVoices();
    const transformedVoices = data.map((v) => ({
      voiceURI: v.id,
      name: v.name,
      lang: v.locale,
      gender: v.gender,
      localService: false,
      source: 'voiceitt',
    }));
    dispatch(slice.actions.setData(transformedVoices));
    return transformedVoices;
  } catch (e) {
    console.error(e);
  }
};

export default {
  slice,
  load,
};
