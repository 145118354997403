import React, { createContext, useEffect } from 'react'

import { apiConfig } from 'src/config'
import { useFeatureFlags } from 'src/hooks/useFeatureFlags'

const ServerEventType = {
  featureFlagsUpdate: 'FEATURE_FLAGS_UPDATE',
}

export const ServerEventsContext = createContext({})

export const ServerEventsContextProvider = ({ children }) => {
  const { setFeatureFlags } = useFeatureFlags()

  const handleMessage = (message) => {
    const { type, data } = JSON.parse(message.data);
    switch (type) {
      case ServerEventType.featureFlagsUpdate:
        setFeatureFlags(data)
        break;
    
      default:
        break;
    }
  }

  useEffect(() => {
    //Disabled for now as it causes 60 seconds reconnect once deployed
    // const generalEventSource = new EventSource(`${apiConfig.api2Url}/app/server-events/general`);
    // generalEventSource.onmessage = handleMessage
    // return () => {
    //   generalEventSource.close()
    // }
  }, [])

  return (
    <ServerEventsContext.Provider value={{}}>
      { children }
    </ServerEventsContext.Provider>
  )
}