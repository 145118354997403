import { config } from 'src/config';
import { getLoginEnvInfo } from 'src/utils/platform';

export const APP_ID = 'casr_web_app';
export const API_KEY = '1edcc6df-c65e-4bd6-95bc-a1be93487385';
export const APP_BUILD = '0.0.31';
export const APP_NAME = 'web_app';
export const LOGIN_EXTRA_INFO = {
  _extra: {
    app_version: APP_BUILD,
    ...getLoginEnvInfo(),
  },
};

export const THEMES = {
  LIGHT: 'LIGHT',
};

export const DICTATION_PROVIDER = 'dictation';
export const DICTATION_SCENARIO_DIFFICULTY_ORDER = [
  'dictation_casr',
  'dictation_casr_medium',
  'dictation_casr_child_up_to_3_grade',
  'dictation_casr_simple',
];

export const MIN_REGISTRATION_AGE = 13;
export const ADULT_AGE = 18;
export const TERMS_OF_SERVICE_LINK =
  'https://www.voiceitt.com/legal/terms-of-service';
export const PRIVACY_POLICY_LINK = 'https://www.voiceitt.com/legal/privacy';
export const OPENAI_TERMS_LINK = 'https://openai.com/policies/terms-of-use';
export const OPENAI_PRIVACY_LINK = 'https://openai.com/policies/privacy-policy';
export const IP_API_LINK = 'https://ipapi.co/json/';

export const INIT_CASR_ERROR = {
  no_model: 'no_model',
};

export const SUPPORT_EMAIL = 'support@voiceitt.com';

export const RECOGNITION_MODE = {
  CONVERSATION: 'conversation',
  DICTATION: 'dictation',
  CHAT_WITH_ASSISTANT: 'chat_with_assistant',
  MEETING_WEBEX: 'meeting_webex',
};

export const NAVIGATION_PAGE = {
  RECORD: 'record',
  SPEAK: 'speak',
  DICTATE: 'dictate',
};

export const RECOGNITION_LANGUAGE = 'en-US';
export const OUTPUT_LANGUAGE = 'en-US';

export const AUDIO_SAMPLE_RATE = 16000;
export const AUDIO_CHANNELS_COUNT = 1;
export const AUDIO_BITS_PER_SAMPLE = 16;

export const WAVE_FORMAT = {
  PCM: 1,
  IEEE_FLOAT: 3,
};

export const PHRASES_SOURCES = {
  STATIC: 'static',
  DATASET: 'dataset',
  EDITED: 'edited',
  CUSTOM: 'custom',
};

export const DEFAULT_DATASET_TRAIN_SET_PHRASES_COUNT = 100;

export const TRAIN_SET_VARIANTS = {
  CUSTOM_PHRASES: 'custom',
  DATASET_PHRASES: 'dataset',
};

export const AUDIO_LIMITS = {
  IGNORE_DURATION: 0.15,
  MIN_DURATION: 1,
  MAX_DURATION: 8,
  CHAR_DURATION: 0.04,
  MIN_VAD_DURATION: 0.3,
};

export const SETTINGS_VOICE_PATH = 'app.web.outputVoiceIdentifier';
export const SETTINGS_INSTALL_PROMPT_CLOSED_PATH =
  'app.web.install_prompt_closed';
export const SETTINGS_MIN_SILENCE_PATH = 'min_silence_length_seconds';
export const SETTINGS_FILTER_PROFANITIES_PATH = 'app.filter_profanities';
export const SETTINGS_CHAT_GPT_ENABLED_PATH = 'app.chat_gpt_enabled';
export const SETTINGS_DICTATION_SCENARIO_PATH = 'app.dictation_scenario';

export const DICTATION_INACTIVITY_FAIL_COUNT = 5;

export const TRAINING_STATE = {
  no_records: 1,
  record_in_progress: 2,
  waiting_for_model: 3,
  model_built: 4,
};

export const MISSING_MODEL_ISSUE = {
  INCOMPLETE_TRAINING: 1,
};

export const ASSISTANT_CHAT_ROLES = {
  USER: 'user',
  BOT: 'bot',
};
