import React from 'react'
import { Box, LinearProgress } from '@mui/material'

const LoadingScreen = () => {
  return (
    <Box sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      minHeight: '100%',
      padding: '15px'
    }}>
      <Box width='40%'>
        <LinearProgress />
      </Box>
    </Box>
  );
};

export default LoadingScreen
