import { WAVE_FORMAT } from 'src/constants'
import { Buffer } from 'buffer'

const prependWavHeaderToAudio = (options = {}, audioData) => {
  const { sampleRate, numChannels, bitsPerSample, waveFormat = WAVE_FORMAT.PCM } = options

  const requiredOptions = { sampleRate, numChannels, bitsPerSample }
  const missingOptions = Object.keys(requiredOptions).filter(k => !requiredOptions[k])
  if (missingOptions.length > 0) {
    throw new TypeError(`prependWavHeaderToAudio requires options arg to contain ${missingOptions.join(', ')} properties set to respective not empty values`)
  }

  let audioBuffer = null
  if (typeof audioData === 'string') {
    audioBuffer = Buffer.from(audioData, 'binary')
  } else if (Buffer.isBuffer(audioData)) {
    audioBuffer = audioData
  } else if (audioData instanceof ArrayBuffer) {
    audioBuffer = Buffer.from(audioData)
  } else {
    throw new TypeError('audioData must be Buffer or string')
  }

  const chunkHeaderSize = 8
  const riffChunkSize = 4
  const subchunkHeaderSize = 8 //Part that contains subchunk name and size

  let fmtSubchunkSize = 0
  if (waveFormat === WAVE_FORMAT.PCM) {
    fmtSubchunkSize = 16
  } else if (waveFormat === WAVE_FORMAT.IEEE_FLOAT) {
    fmtSubchunkSize = 18
  } else {
    throw new TypeError('unknown waveFormat')
  }
  const headerSize = chunkHeaderSize + riffChunkSize + subchunkHeaderSize + fmtSubchunkSize + subchunkHeaderSize

  const header = new Buffer.alloc(headerSize)

  //Header
  header.write('RIFF', 0)
  const remainingChunkSize = headerSize + audioBuffer.length - chunkHeaderSize
  header.writeUInt32LE(remainingChunkSize, 4)
  //Chunk data
  header.write('WAVE', 8)

  //Subchunk header
  header.write('fmt ', 12)
  header.writeUInt32LE(fmtSubchunkSize, 16)
  //Subchunk data
  header.writeUInt8(waveFormat, 20)
  header.writeUInt8(numChannels, 22)
  header.writeUInt32LE(sampleRate, 24)
  header.writeUInt32LE(sampleRate * numChannels * bitsPerSample / 8, 28)
  header.writeUInt8(numChannels * bitsPerSample / 8, 32)
  header.writeUInt8(bitsPerSample, 34)

  if (waveFormat === WAVE_FORMAT.PCM) {
    //Subchunk header
    header.write('data', 36)
    header.writeUInt32LE(audioBuffer.length, 40)
  } else if (waveFormat === WAVE_FORMAT.IEEE_FLOAT) {
    const extensionSize = 0 //For all formats other than PCM, the Format chunk must have an extended portion. The extension can be of zero length, but the size field (with value 0) must be present.
    header.writeUInt8(extensionSize, 36)
    
    //Subchunk header
    header.write('data', 38)
    header.writeUInt32LE(audioBuffer.length, 42)
  }

  const fullBuffer = Buffer.concat([header, audioBuffer])
  return new Blob([fullBuffer], { type: 'audio/wav' })
}

export default prependWavHeaderToAudio