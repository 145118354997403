import { useState, useEffect, useRef } from 'react'

import useAppVisibility from 'src/hooks/useAppVisibility'
import logger from 'src/lib/logger'
import useIntegrations from 'src/hooks/useIntegrations'


const useAppInactivity = () => {
  const timeoutTimerId = useRef()

  const isAppVisible = useAppVisibility()
  const { hasActiveIntegration } = useIntegrations()

  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    clearTimeout(timeoutTimerId.current)

    if (isAppVisible || hasActiveIntegration()) {
      setIsActive(true)
    } else {
      timeoutTimerId.current = setTimeout(() => {
        setIsActive(false)
        logger.info('App is inactive')
      }, 60 * 1000)
    }
  }, [isAppVisible])

  return !isActive
}
export default useAppInactivity
