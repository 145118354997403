import axios from 'axios'

class TeamsApi {
  
  axiosInstance = axios.create()

  async send(url, text) {
    return await this.axiosInstance.post(url, text,
      {
        headers: {
          'Content-Type': 'text/plain',
        }
      })
  }
}

export const teamsApi = new TeamsApi()
