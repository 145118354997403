let event_keys = new Set(); 
let events = {};

export function clear() {
    event_keys = new Set();
    events = {};
}

export function time(e) {
    event_keys.add(e);

    let e_log = events[e];
    if (!e_log) {
        e_log = {
            start: null,
            durations: []
        };
    }

    if (!e_log.start) {
        e_log.start = performance.now() / 1000;
    } else {
        console.log("Event already started:", e);
    }

    events[e] = e_log;

    // console.log(e, "e_log", e_log);
    // console.time(e);
}

export function timeEnd(e) {
    let e_log = events[e];
    if (!e_log) {
        console.log("Event not started:", e);
        return;
    }

    // console.log(e, "e_log", e_log);

    const duration = performance.now() / 1000 - e_log.start;

    // console.log(e, "duration", duration);

    e_log.start = null;
    e_log.durations.push(duration);

    events[e] = e_log;

    // console.timeEnd(e);
}

export function log_perf_stats() {
    const ek = Array.from(event_keys).sort();
    let event_stats = {}

    // console.log("event_keys:", event_keys);

    for (const e of ek) {
        let e_log = events[e];

        const count = e_log.durations.length;
        const total = e_log.durations.reduce((a, b) => a + b, 0);
        const average = total / count;
        const variance = e_log.durations.reduce((sum, x) => sum + Math.pow(x - average, 2), 0) / count;
        const stdDev = Math.sqrt(variance);

        console.log("event:", e, "total:", total, "average:", average, "stdDev:", stdDev, "count:", count);

        event_stats[e] = {
            count: count,
            total: total,
            average: average,
            stdDev: stdDev,
            conf: 1.96 * stdDev / Math.sqrt(count)

        }
    }

    return event_stats;
}