/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import { ErrorBoundary } from 'react-error-boundary';
import StylesProvider from '@mui/styles/StylesProvider';
import { create as createJSS } from 'jss';
import routes from './routes';
import { createTheme } from './theme';
import { THEMES } from './constants';
import GlobalStyles from './components/GlobalStyles';
import ErrorFallback from './pages/error';
import NetworkMonitor from 'src/components/NetworkMonitor';
import RecognitionUnlocked from 'src/components/RecognitionUnlocked';
import CookiesConsent from 'src/components/CookiesConsent';

const jss = createJSS({ plugins: jssPreset().plugins });

function App() {
  useEffect(() => {
    const init = async () => {
      if ('serviceWorker' in navigator) {
        try {
          await navigator.serviceWorker.register('/pwa-sw.js');
        } catch (error) {
          console.error(error);
        }
      }
    };
    init();
  }, []);

  const theme = createTheme({
    theme: THEMES.LIGHT,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <GlobalStyles />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {useRoutes(routes)}
            <NetworkMonitor />
            <RecognitionUnlocked />
            <CookiesConsent />
          </ErrorBoundary>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
