import { LocalRecogniserModelTypes, ModelLinks, ModelVersion } from './types';

export const modelLinksToModelVersions = (
  modelLinks: ModelLinks,
): ModelVersion[] => {
  const modelVersions: ModelVersion[] = [];
  for (const modelType of [...LocalRecogniserModelTypes, 'shared']) {
    const modelInfo = modelLinks[modelType];
    if (modelInfo) {
      try {
        modelVersions.push({ [modelType]: modelInfo.version });
      } catch (error) {
        continue;
      }
    }
  }
  return modelVersions;
};
