import { createContext, useRef, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import getCountryISO2 from 'country-iso-3-to-2';

import useAuth from 'src/hooks/useAuth';
import usePrevious from 'src/hooks/usePrevious';
import useSettings, { SettingsKeys } from 'src/hooks/useSettings';

const initialState = {};
export const AcapellaContext = createContext(initialState);

const ACAPELLA_VOICE_PREFIX = 'com.acapella.';

export const AcapellaProvider = ({ children }) => {
  const [error, setError] = useState();
  const [account, setAccount] = useState();
  const [voices, setVoices] = useState();

  const fetchVoicesPromise = useRef();

  const settings = useSettings();

  useEffect(() => {
    refresh();

    return () => {};
  }, []);

  const updateAccountInfo = async (token) => {
    if (!token) {
      return;
    }

    const account = await getAccount(token);
    let error;
    if (!account.tos_accepted) {
      error = 'Terms of Service have not been accepted.';
    } else if (account.credit === 0) {
      error = 'No credits.';
    }
    setError(error);
    setAccount(account);
    const convertedVoices = convertVoices(account.voices);
    setVoices(convertedVoices);
    return { error, account, voices: convertedVoices };
  };

  const refresh = async () => {
    const token = settings.state[SettingsKeys.acapellaToken];
    updateAccountInfo(token);
  };

  const canUse = (text, voiceURI) => {
    const token = settings.state[SettingsKeys.acapellaToken];
    return (
      token && voiceURI.startsWith(ACAPELLA_VOICE_PREFIX) && text.length < 2048
    );
  };

  const login = async (email, password) => {
    const response = await axios.post(
      'https://www.acapela-cloud.com/api/login/',
      {
        email: email,
        password: password,
      },
    );
    const token = response.data.token;
    settings.actions.set(SettingsKeys.acapellaToken, token);

    const accountInfo = await updateAccountInfo(token);

    return { token, ...accountInfo };
  };

  const setLoginData = (loginData) => {
    const { token, error, account, voices } = loginData;
    settings.actions.set(SettingsKeys.acapellaToken, token);

    setError(error);
    setAccount(account);
    setVoices(voices);
  };

  const logout = async () => {
    const token = settings.state[SettingsKeys.acapellaToken];
    await axios.get('https://www.acapela-cloud.com/api/logout/', {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    settings.actions.set(SettingsKeys.acapellaToken, null);

    setError();
    setAccount();
    setVoices();
  };

  const convertVoices = (voices) => {
    const genderMap = {
      1: 'female',
      2: 'male',
    };

    return voices.map((v) => {
      const [lang, region] = v.locale.split('-');
      const localeLanguage = new Intl.Locale(lang).language;
      let locale = `${localeLanguage}-${getCountryISO2(region.toUpperCase()) || region}`;
      return {
        voiceURI: ACAPELLA_VOICE_PREFIX + v.name,
        name: formatVoiceName(v.name) + (v.type && ` (${v.type})`),
        lang: locale,
        gender: genderMap[v.gender],
        localService: false,
        source: 'acapella',
      };
    });
  };

  const fetchVoices = async () => {
    const token = settings.state[SettingsKeys.acapellaToken];
    if (!token) {
      throw new Error('Not authenticated in Acapella');
    }
    if (!voices && !fetchVoicesPromise.current) {
      fetchVoicesPromise.current = (async () => {
        const result = await updateAccountInfo(token);
        if (!result) {
          return [];
        }
        const { voices } = result;
        return voices;
      })();
    }
    try {
      return (await fetchVoicesPromise.current) || voices || [];
    } catch (error) {
      return [];
    }
  };

  const getAccount = async (token) => {
    const response = await axios.get(
      'https://www.acapela-cloud.com/api/account/',
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    );
    return response.data;
  };

  const getTTSUrl = (voice, text, speed, token) => {
    return (
      'https://www.acapela-cloud.com/api/command/' +
      '?voice=' +
      voice.replaceAll(ACAPELLA_VOICE_PREFIX, '') +
      '&text=' +
      encodeURIComponent(text) +
      '&output=file' +
      '&type=mp3' +
      '&speed=' +
      speed * 100 +
      '&shaping=100' +
      '&volume=32768' +
      '&token=' +
      token
    );
  };

  const downloadAudioBlob = async (text, voiceURI, speed, signal) => {
    const token = settings.state[SettingsKeys.acapellaToken];
    if (!token) {
      throw new Error('Acapella: not authenticated');
    }
    if (!voiceURI) {
      throw new Error('Acapella: no selected voice');
    }
    try {
      const response = await axios.get(
        getTTSUrl(voiceURI, text, speed, token),
        {
          responseType: 'blob',
          signal,
        },
      );
      return new Blob([response.data]);
    } catch (error) {
      if (
        error.response &&
        error.response.headers['content-type'] === 'application/json'
      ) {
        let json;
        try {
          json = JSON.parse(await error.response.data.text());
        } catch (parsingError) {
          throw error;
        }
        throw new Error(
          json?.error ? `Acapella: ${json.error}` : 'Something went wrong',
        );
      } else {
        throw error;
      }
    }
  };

  const { isAuthenticated } = useAuth();
  const prevIsAuthenticated = usePrevious(isAuthenticated);

  useEffect(() => {
    const isLoggedOut = !isAuthenticated && prevIsAuthenticated;
    if (isLoggedOut) {
      logout();
    }
  }, [isAuthenticated, prevIsAuthenticated]);

  return (
    <AcapellaContext.Provider
      value={{
        login,
        setLoginData,
        logout,
        canUse,
        getTTSUrl,
        downloadAudioBlob,
        account,
        isAuthenticated: !!account,
        error,
        fetchVoices,
        refresh,
      }}
    >
      {children}
    </AcapellaContext.Provider>
  );
};

export const useTTSAcapella = () => {
  const context = useContext(AcapellaContext);
  if (context === undefined) {
    throw new Error('useTTSAcapella must be used within a AcapellaProvider');
  }

  return context;
};

const formatVoiceName = (input) => {
  // Extract the portion before the number
  const beforeNumber = input.match(/^[^\d]+/)[0];

  // Add spaces before each capital letter (excluding the first one)
  const formatted = beforeNumber.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Add spaces between words that are joined without spaces
  const result = formatted.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

  return result;
};
