export const openPopup = (url) => {
  const width = 400;
  const height = 600;
  const left = window.screenX + (window.innerWidth - width) / 2;
  const top = window.screenY + (window.innerHeight - height) / 2;

  return window.open(
    url,
    'voiceitt:auth:popup',
    `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`
  );
};

export const runPopup = (config) => {
  return new Promise((resolve, reject) => {
    let popupEventListener;

    // Check each second if the popup is closed triggering a PopupCancelledError
    const popupTimer = setInterval(() => {
      if (config.popup && config.popup.closed) {
        clearInterval(popupTimer);
        clearTimeout(timeoutId);
        window.removeEventListener('message', popupEventListener, false);
        reject(new Error('PopupCancelledError'));
      }
    }, 1000);

    let timeoutId
    if (config.timeoutInSeconds) {
      timeoutId = setTimeout(() => {
        clearInterval(popupTimer);
        reject(new Error('PopupTimeoutError'));
        window.removeEventListener('message', popupEventListener, false);
      }, (config.timeoutInSeconds0) * 1000);
    }

    popupEventListener = function (e) {
      if (!e.data || e.data.type !== config.msgType) {
        return;
      }

      clearTimeout(timeoutId);
      clearInterval(popupTimer);
      window.removeEventListener('message', popupEventListener, false);

      resolve(e.data);

      config.popup?.close();
    };

    window.addEventListener('message', popupEventListener);
  });
};